<template>
  <div>
    <r-header :title="'我的摊位'" :isLeftArrow="true" :isR="true" @back="backPage"></r-header>
    <van-loading v-if="pageloading" class="page-loading" type="spinner" color="#1989fa" />
    <van-pull-refresh v-else v-model="refreshing" @refresh="onRefresh" style="margin-top: 1rem;margin-bottom: 2rem">
      <van-list
        v-model="loading"
        :finished="finished"
        offset="300"
        @load="onLoad"
      >
        <template v-if="list.length > 0">
          <van-cell-group
          v-for="item in list"
          :key="item.id">
            <van-card
              num="1"
              style="margin-bottom:2px;"
            >
              <template #title>
                <span class="text-m">{{ item.shopname }}</span>
              </template>
              <template #price>
                <p class="price-text">¥ <span class="money">{{ fmoney(item.shopprice, 2) }}</span></p>
              </template>
              <template #desc>
                <p class="mt-10">开始时间：{{ item.starttime }}</p>
                <p>到期时间：{{item.endtime}}</p>
                <!-- <p style="margin-top:10px;">状态：{{ item.laststatus === '0' ? '待付款' : item.paystatus === '1' ? '已付款' : '已取消' }}</p> -->
              </template>
              <template #footer>
                <template v-if="item.orderstatus === '1'">
                  <p style="margin-bottom:10px;">{{ item.laststatus === '0' ? '待付款' : item.laststatus === '1' ? '已付款' :  item.laststatus === '2' ? '已过期' : '已取消' }}</p>
                  <template v-if="item.laststatus === '2'">
                    <van-button type="danger" size="small" @click="delItem(item)">删除</van-button>
                  </template>
                  <template v-if="item.laststatus === '1'">
                    <div class="mb-10 flex justify-end align-center">
                      <van-stepper v-model="item.round" :max="max" theme="round" button-size="22" disable-input />
                      <span class="text ml-10">/ 周</span>
                    </div>
                    <van-button type="primary" size="small" @click="goPay(item, 'R', item.round)">续费</van-button>
                  </template>
                  <van-button type="danger" size="small" v-if="item.laststatus === '0'" @click="cancelOrder(item.id)">取消支付</van-button>
                  <van-button style="margin-left:20px;" type="primary" size="small" v-if="item.laststatus === '0'" @click="goPay(item, '2')">继续支付</van-button>
                </template>
                <template v-else>
                  <p class="money">{{ item.msg }}</p>
                </template>
              </template>
            </van-card>
          </van-cell-group>
        </template>
        <van-empty v-if="!loading && list.length < 1" image="search" description="暂时没有订单" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import RHeader from '@/components/RHeader.vue'
export default {
  name: 'StallOrderList',
  components: {
    RHeader
  },
  data () {
    return {
      list: [],
      disabled: false,
      loading: true,
      finished: false,
      refreshing: false,
      page: 1,
      page_size: 10,
      total: 0,
      pages: 0,
      pageloading: false,
      max: 0
    }
  },
  mounted () {
    this.initData()
    this.getMaxLimit()
  },
  methods: {
    backPage () {
      this.$router.go(-1)
    },
    getMaxLimit () {
      this.$axios.get('/gw/tanweixulimit').then(async res => {
        if (res.data.code === 200) {
          this.max = res.data.data
        }
      })
    },
    initData () {
      this.$axios.post('/gw/zutanwei', {
        shoptype: '1',
        currentPage: this.page,
        pageSize: this.page_size
      }).then(res => {
        if (res.data.code === 200) {
          if (res.data.data.length === 0) {
            this.list = []
            this.finished = true
          }
          this.total = res.data.total
          const dataList = res.data.data
          if (this.page === 1) {
            this.list = dataList
          } else {
            this.list = this.list.concat(dataList)
          }
          this.loading = false
          if (this.list.length >= res.data.total) {
            this.finished = true
            this.loading = false
          }
          this.page++
        } else if (res.data.code === 100) {
          this.finished = true
          this.loading = false
          this.$router.push({
            name: 'login'
          })
        }
      }).catch(() => {
        this.finished = true
        this.loading = false
      })
    },
    onLoad () {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.initData()
          this.timer = null
        }, 500)
      }
    },
    onRefresh () {
      this.loading = true
      this.page = 1
      this.list = []
      this.refreshing = true
      this.onLoad()
      this.finished = false
      this.refreshing = false
    },
    goPay (item, type, round) {
      // item.disabled = true
      const t = round ? `${type}${round}` : type
      this.pageloading = true
      if (this.pageloading) {
        const redirect = 'http://www.whwwsc.com/movie/h5pay/'
        const orderId = `${t}*${item.id}`
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx63c208bb195251ca&redirect_uri=${encodeURIComponent(redirect)}&response_type=code&scope=snsapi_userinfo&state=${orderId}#wechat_redirect`
      }
    },
    delItem (item) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除已过期记录？'
      }).then(() => {
        this.$axios({
          method: 'post',
          url: '/gw/delorder',
          data: {
            orderid: item.id
          }
        }).then(res => {
          if (res.data.code === 200) {
            this.onRefresh()
            this.$toast({
              type: 'success',
              message: '已删除'
            })
          }
        })
      }).catch(() => {
        // on cancel
      })
    },
    cancelOrder (id) {
      this.$dialog.confirm({
        title: '提示',
        message: '您即将取消支付，确认取消吗？'
      }).then(() => {
        this.$axios({
          method: 'post',
          url: '/gw/cancel',
          data: {
            orderid: id
          }
        }).then(res => {
          if (res.data.code === 200) {
            this.onRefresh()
            this.$toast({
              type: 'success',
              message: '已取消支付'
            })
          }
        })
      }).catch(() => {
        // on cancel
      })
    }
  }
}
</script>

<style lang="less" scoped>
.price-text{
  font-size: 14px;
  .money{
    font-size: 18px;
    font-weight: bold;
  }
}
.page-loading {
  text-align: center;
  margin-top: 3rem;
}
</style>
